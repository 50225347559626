import React from 'react'
import { injectIntl } from 'gatsby-plugin-intl'
// import Layout from '../components/layout'
import SEO from '../components/seo'
import Maintenance from '../components/service/maintenance'

const MaintenancePage = ({ intl }) => (
  <>
    <SEO title={intl.formatMessage({ id: 'pages.maintenance.title' })} />
    <div
      style={{
        maxWidth: `800px`,
        margin: `0 auto`,
      }}
    >
      <Maintenance />
    </div>
  </>
)

export default injectIntl(MaintenancePage)
