import React, { Component } from 'react'
import { FormattedMessage } from 'gatsby-plugin-intl'
import axios from '../axios'

class Maintenance extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fetchedData: [],
    }
  }

  getMaintenanceStatus() {
    axios
      .get('/get/maintenance_status', {})
      .then(res => {
        const data = res.data
        this.setState({
          fetchedData: data,
        })
      })
      .catch(e => {
        console.error(e)
      })
  }

  clearStatus = () => {
    axios
      .post('/post/maintenance_status_reset', {
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          maintenancereset: true,
        },
      })
      .then(res => {
        console.log(res)
        this.getMaintenanceStatus()
      })
      .catch(e => {
        console.error(e)
      })
  }

  componentDidMount() {
    this.getMaintenanceStatus()
  }

  render() {
    const { fetchedData } = this.state
    return (
      <>
        <div className='interfaceRow justifyCenter'>
          <FormattedMessage id='pages.maintenance.title' />
        </div>
        <div className='interfaceRow'>
          <div className='third'>
            <label htmlFor='presetRunningTime'>
              <FormattedMessage id='pages.maintenance.presetrunningtime' />:
            </label>
          </div>
          <div className='quart'>{fetchedData.maintenance_setpoint}</div>
          <div className='third'>
            <FormattedMessage id='pages.maintenance.days' />
          </div>
        </div>
        <div className='interfaceRow'>
          <div className='third'>
            <label htmlFor='actualRunningTime'>
              <FormattedMessage id='pages.maintenance.actualrunningtime' />:
            </label>
          </div>
          <div className='quart'>{fetchedData.maintenance_actual}</div>
          <div className='third'>
            <FormattedMessage id='pages.maintenance.days' />
          </div>
        </div>
        <div className='interfaceRow justifyCenter'>
          <button onClick={this.clearStatus}>
            <FormattedMessage id='pages.maintenance.resetbutton' />
          </button>
        </div>
      </>
    )
  }
}

export default Maintenance
